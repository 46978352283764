<template>
  <LoadingOverlay v-if="routeLoading" />

  <div class="min-h-screen bg-white">
    <slot />
  </div>

  <Footer />
</template>

<script setup>
const nuxtApp = useNuxtApp()
const routeLoading = ref()

nuxtApp.hooks.hook('page:loading:start', () => {
  routeLoading.value = true
})

nuxtApp.hooks.hook('page:loading:end', () => {
  routeLoading.value = false
})
</script>
